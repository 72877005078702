import Grid from "@material-ui/core/Grid";

import Link from "@material-ui/core/Link";

import React from "react";

import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";

// const footers = [
//   {
//     title: "Resources",
//     description: [
//       "Resource",
//       "Resource name",
//       "Another resource",
//       "Final resource",
//     ],
//   },
//   {
//     title: "Legal",
//     description: ["Privacy policy", "Terms of use"],
//   },
// ];

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  test: {
    background: "#4b4b4b",
    position: "absolute",
    bottom: "0",
    width: "100%",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: "12vmin",
    margin: theme.spacing(1),
  },
  div1: {
    textAlign: "left",
  },
  footer: {
    // borderTop: `1px solid ${theme.palette.divider}`,
    color: "#b2b2b2",
    // position: "absolute",
    // width: "100%",
    // bottom: "0",
    background: "#4b4b4b",
    marginTop: theme.spacing(0),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
    },
  },
}));
// var rights = {
//   position: "absolute",
//   bottom: "0",
//   marginLeft: "700px",
//   display: "block",
//   fontWeight: "bold",
//   color: "#00917c",
// };
function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl" component="footer" className={classes.footer}>
        <Grid container spacing={4} justifyContent="space-evenly">
          <Grid item xs={6} sm={3}>
            <img src="/images/set/Icon-63.png" className={classes.logo}></img>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            // container
            // justifyContent="flex-start"
          >
            <div className={classes.div1}>Modela IoT CO.,LTD.</div>
            <div className={classes.div1}>
              Market Today MG10 Soi. Krungthepkritha 7
            </div>
            <div className={classes.div1}>
              Huamak Bangkapi Bangkok 10240 Thailand
            </div>
            <div className={classes.div1}>
              Sales: 06-1920-3920, Business: 06-2021-2255
            </div>
            <div className={classes.div1}>
              Mon.-Fri. 8:30 am. - 17:30 pm. in Thailand
            </div>
          </Grid>
          <Grid item xs={6} sm={1}></Grid>
          <Grid item xs={6} sm={2}>
            <ul className={classes.div1}>
              <li>
                <Link
                  href={"https://modela.co.th/privacy-policy-2/"}
                  target="_blank"
                  variant="subtitle1"
                  color="inherit"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link href={"/application"} variant="subtitle1" color="inherit">
                  Application
                </Link>
              </li>
              <li>
                <Link href={"/contact"} variant="subtitle1" color="inherit">
                  Contact
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={1}></Grid>
          <Grid item xs={6} sm={2}>
            <ul className={classes.div1}>
              <li>
                <Link href={"/dashboard"} variant="subtitle1" color="inherit">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link href={"/store"} variant="subtitle1" color="inherit">
                  Store
                </Link>
              </li>
              <li>
                <Link
                  href={"https://forms.gle/P6uc14ECzND82HDB6"}
                  target="_blank"
                  variant="subtitle1"
                  color="inherit"
                >
                  Apply Dealer
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <div
        className="line"
        style={{
          borderTop: "1px solid  ",
          margin: "20px auto",
          marginBottom: "20px",

          width: "90%",
        }}
      ></div>
      <Container maxWidth="xl">
        <div>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={6} sm={3}>
              <IconButton
                color="primary"
                className={classes.button}
                href={"https://shopee.co.th/modela.iot.store"}
                target="_blank"
              >
                <img
                  style={{ width: 35 }}
                  src="/images/set/Untitled-1-62.png"
                />
              </IconButton>
              <IconButton
                color="primary"
                className={classes.button}
                href={"https://www.lazada.co.th/modela-iot-store"}
                target="_blank"
              >
                <img
                  style={{ width: 35 }}
                  src="/images/set/Untitled-1-63.png"
                />
              </IconButton>
              <IconButton
                color="primary"
                className={classes.button}
                href={"https://www.facebook.com/Modela.InternetofTHINK/"}
                target="_blank"
              >
                <img
                  style={{ width: 35 }}
                  src="/images/set/Untitled-1-64.png"
                />
              </IconButton>
              <IconButton
                color="primary"
                className={classes.button}
                href={"https://page.line.me/modelaiot?openQrModal=true"}
                target="_blank"
              >
                <img
                  style={{ width: 35 }}
                  src="/images/set/Untitled-1-65.png"
                />
              </IconButton>
              <IconButton
                color="primary"
                className={classes.button}
                href={"https://www.youtube.com/@ModelaIoTChannel/videos"}
                target="_blank"
              >
                <img
                  style={{ width: 35 }}
                  src="/images/set/Untitled-1-66.png"
                />
              </IconButton>
            </Grid>
            <Grid item sm={3}></Grid>
            <Grid item sm={3}></Grid>
            <Grid item xs={6} sm={3}>
              <IconButton
                color="primary"
                className={classes.button}
                href={
                  "https://apps.apple.com/th/app/modela-iot/id1382251131?l=th"
                }
                target="_blank"
              >
                <img style={{ width: 120 }} src="/images/set/appstore.png" />
              </IconButton>
              <IconButton
                color="primary"
                className={classes.button}
                href={
                  "https://play.google.com/store/apps/details?id=cc.blynk.appexport.modelaiot&hl=th&gl=US&pli=1"
                }
                target="_blank"
              >
                <img style={{ width: 120 }} src="/images/set/googleplay.png" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="sm">
        <p>&copy; 2023 Modela IoT CO.,LTD. All Right Reserved </p>
      </Container>
    </footer>
  );
}
export default Footer;
