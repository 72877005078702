import React from "react";
import { Typography } from "@material-ui/core";
import { Button, IconButton } from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: "8vmin",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(2),
  },
  appBar: {
    backgroundColor: "#fbb116",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
  },
  iconbutton: {
    // marginTop: theme.spacing(9),
  },
  div: {
    margin: theme.spacing(20),
  },
}));

function Store() {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      <Grid container direction="row">
        <Grid item xs={6} md={3}>
          <IconButton
            className={classes.iconbutton}
            href={"https://www.store.modela.co.th/"}
            target="_blank"
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <img style={{ width: 120 }} src="/images/set/Icon-61.png" />
              </Grid>
              <Grid>
                <h6>www.store.modela.co.th</h6>
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3}>
          <IconButton
            className={classes.iconbutton}
            href={"https://shopee.co.th/modela.iot.store"}
            target="_blank"
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <img style={{ width: 120 }} src="/images/set/Icon-47.png" />
              </Grid>
              <Grid>
                {/* <Typography>shopee.co.th/modela.iot.store</Typography> */}
                <h6>shopee.co.th/modela.iot.store</h6>
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3}>
          <IconButton
            className={classes.iconbutton}
            href={"https://www.lazada.co.th/modela-iot-store"}
            target="_blank"
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <img style={{ width: 120 }} src="/images/set/Icon-49.png" />
              </Grid>
              <Grid>
                <h6>lazada.co.th/modela-iot-store</h6>
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3}>
          <IconButton
            className={classes.iconbutton}
            href={"https://www.facebook.com/Modela.InternetofTHINK/"}
            target="_blank"
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <img style={{ width: 120 }} src="/images/set/Icon-50.png" />
              </Grid>
              <Grid>
                <h6>Modela.InternetofTHINK/</h6>
              </Grid>
            </Grid>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default Store;
