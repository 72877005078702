import React, { useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx"; //ใช้ classname ได้มากกว่า 1 class
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 80,
      width: "100%",
      backgroundColor: "#F7E9CB",
    },
  },
})((props) => (
  <Tabs centered {...props} TabIndicatorProps={{ children: <span /> }} />
));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: "8vmin",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(2),
  },
  appBar: {
    backgroundColor: "#fbb116",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(0),
    position: "absolute",
    right: "0px",
  },
  title: {
    flexGrow: 1,
  },
  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
  },
}));

const Header = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
    setAnchorEl(null);
  };

  const menuItems = [
    {
      menuTitle: "Home",
      pageURL: "/",
    },
    {
      menuTitle: "Store",
      pageURL: "/store",
    },
    {
      menuTitle: "Application",
      pageURL: "/application",
    },
    {
      menuTitle: "Contact",
      pageURL: "/contact",
    },
  ];

  const [value, setValue] = React.useState();

  useEffect(() => {
    console.log(window.location.pathname);

    switch (window.location.pathname) {
      case "/":
        setValue(0);
        break;
      case "/store":
        setValue(1);
        break;
      case "/application":
        setValue(2);
        break;
      case "/contact":
        setValue(3);
        break;
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar>
          <NavLink
            to="/"
            className={classes.brand}
            onClick={() => {
              setValue(0);
            }}
          >
            <img src="/images/set/Icon-60.png" className={classes.logo}></img>
          </NavLink>

          {isMobile ? (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map((menuItem) => {
                  const { menuTitle, pageURL } = menuItem;
                  return (
                    <MenuItem onClick={() => handleMenuClick(pageURL)}>
                      {menuTitle}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          ) : (
            <div className={classes.headerOptions}>
              <div>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example"
                >
                  <StyledTab label="HOME" component={Link} to="/" />
                  <StyledTab label="STORE" component={Link} to="/store" />
                  <StyledTab
                    label="APPLICATION"
                    component={Link}
                    to="/application"
                  />
                  <StyledTab label="CONTACT" component={Link} to="/contact" />
                </StyledTabs>
                <Typography className={classes.padding} />
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
