import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const contentSmartset = [
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-33.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-36.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-38.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-30.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-31.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-32.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/Icon-35.png",
    user: "Luanda Gjokaj",
  },
  // {
  //   title: "Vulputate Mollis Ultricies",
  //   image: "/images/set/Icon2-02.png",
  //   user: "Luanda Gjokaj",
  // },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/smart1.jpg",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/smart2.jpg",
    user: "Luanda Gjokaj",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/set/smart4.jpg",
    user: "Luanda Gjokaj",
  },
];

const content = [
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-29.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-21.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-01.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-05.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-19.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-14.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-16.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-07.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-15.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-04.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-10.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-11.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-08.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-27.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-12.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-09.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-02.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-22.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-06.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-18.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-03.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-24.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-25.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-28.png",
    user: "Bruno Vizovskyy",
  },
  {
    title: "Vulputate Mollis Ultricies",
    image: "/images/customer/Icon-23.png",
    user: "Luanda Gjokaj",
  },
  {
    title: "Tortor Dapibus Commodo",
    image: "/images/customer/Icon-20.png",
    user: "Erich Behrens",
  },
  {
    title: "Phasellus volutpat metus",
    image: "/images/customer/Icon-13.png",
    user: "Bruno Vizovskyy",
  },
];

const useStyle = makeStyles((theme) => ({
  root: {
    //   backgroundImage: "url(" + backgroundpic + ")",
    // height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundPosition: "center",
    alignItems: "center",
  },
  container: {
    paddingTop: "40px",
    background: "#323232",
    color: "#fff",
    paddingBottom: theme.spacing(30),
  },
  container1: {
    padding: theme.spacing(20),
    background: "#fff",
  },

  item: {
    textAlign: "center",
    "& img": {
      margin: " auto",
      marginBottom: theme.spacing(2),
    },
  },
  paperStyle: {
    padding: 20,
    height: "70vh",
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      width: "100%",
      height: "100%",
    },
    backgroundColor: "rgba(230, 230, 250, 0.3)",
    backdropFilter: "blur(8px)",
  },
  logo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  div: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(6),
  },
  submit: {
    background: "linear-gradient(45deg, #8C52FF 30%, #5653ff 90%)",
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    border: 0,
    color: "white",
    // backgroundColor: "#3f51b5",
    margin: theme.spacing(6, 0, 2),
  },
  typo: {
    textAlign: "left",
  },
  typo2: {
    textAlign: "left",
    textIndent: "50px",
  },
  typo1: {
    textAlign: "left",
    marginTop: theme.spacing(1),
    // textIndent: "50px",
  },
  customerdiv: {
    background: "#323232",
    margin: "auto",
  },
  divyello: {
    background: "#fbb116",
    height: "19vh",
    width: "100%",
    // marginTop: theme.spacing(5)
    // paddingTop: theme.spacing(1)
  },
  customercontainer: {
    // margin: theme.spacing(5),
    padding: theme.spacing(10),
    marginLeft: theme.spacing(20),
    marginRight: theme.spacing(20),
  },
  smartsetcontainer: {
    padding: theme.spacing(10),
    marginLeft: theme.spacing(25),
    marginRight: theme.spacing(25),
  },
  div2: {
    background: "#323232",
  },
  test: {
    padding: theme.spacing(35),
    color: "#fff",
    fontSize: "45px",
  },
  texteasy: {
    color: "#4b4b4b",
    fontSize: "45px",
    margin: theme.spacing(2),
  },
  pic: {
    position: "absolute",
    top: "1300px",
    right: "200px",
  },
  imgtel: {
    // margin: "auto"
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
    [theme.breakpoints.up("xl")]: {
      left: "0px",
    top: "170%",
    },
    [theme.breakpoints.down("md")]: {
      left: "0px",
    top: "160%",
    },
    left: "0px",
    top: "180%",
  },
  gridimg: {
    marginTop: theme.spacing(7),
    // marginBottom: theme.spacing(5),
  },
  gridyello: {
    marginTop: theme.spacing(2),
  },
  containercustomer: {
    color: "#fff",
    background: "#fbb116",
    borderRadius: "25px",
    width: "300px",
    height: "50px",
    // display: "flex",
    // alignItems: "center",
    // textAlign: "center",
    // alignContent: "center",
    // alignSelf: "center"
    // paddingTop: theme.spacing
    // position: "absolute",
    // top: "2450px"
    //
  },
  ss: {
    paddingTop: theme.spacing(3),
    // alignItems: "center",
    // alignContent: "center"
  },
  topich2: {},
}));

function Home() {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    speed: 1500,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    autoplay: true,
    slidesToShow: 3,
    rows: 3,
    slidesToScroll: 3,
    cssEase: "ease-out",
  };

  const settingsmartset = {
    dots: true,
    infinite: true,
    centerMode: false,
    speed: 1000,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    autoplay: true,
    slidesToShow: 3,
    rows: 2,
    slidesToScroll: 1,
    cssEase: "ease-out",
  };

  const classes = useStyle();

  return (
    <div>
      <div className={classes.div2}>
        <img src={"images/set/landing.png"} style={{ width: "100%" }}></img>
      </div>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.gridimg}
          // spacing={0}
        >
          <Grid item md={6} sm={6}>
            <img src="/images/set/banner1.png" style={{ width: "70%" }}></img>
          </Grid>
          <Grid item md={6} sm={6}>
            <Typography className={classes.typo2}>
              Modela IoT is a Thai startup that develops IoT (Internet of
              Things) products for controlling electrical devices automatically
              through the internet with smartphones and embedded smart devices
              in machines. They have a set of IoT control products that work
              through mobile phones and connect to cloud servers to measure data
              with more than 50 types of sensors. The products are ready-to-use
              for end users and cover the following applications:
            </Typography>
            {/* <Typography className={classes.typo1}>
              Modela IoT company, this high-tect product Designed and assembled
              in Thailand for controlling electrical devices by measuring
              sensors
            </Typography> */}
            {/* <Typography className={classes.typo}>
              It can be used in a variety apply such as
            </Typography> */}
            <Typography className={classes.typo1}>&diams; Agriculture</Typography>
            <Typography className={classes.typo}>
            &diams; Livestock and aquaculture
            </Typography>
            <Typography className={classes.typo}>&diams; Industry</Typography>
            <Typography className={classes.typo}>&diams; Home</Typography>
            <Typography className={classes.typo}>
            &diams; Environment and air quality monitors
            </Typography>
            <Typography className={classes.typo}>
            &diams; Wastewater treatment systems
            </Typography>
          </Grid>

          {/* <div className={classes.divyello}></div> */}
          {/* <Grid item md={6} sm={6}>
            <img src="/images/set/Icon2.png" style={{ width: 700 }}></img>
          </Grid> */}
        </Grid>
      </div>
      <img
        src="images/set/Icon3.png"
        className={classes.imgtel}
        style={{ width: "100%" }}
      />
      {/* <div className={classes.divyello}> */}
      {/* <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={5}
          className={classes.gridyello}
        > */}
      {/* <Grid item md={12} sm={12}> */}
      {/* <Typography className={classes.texteasy}>
              Easy on SmartPhone
            </Typography> */}
      {/* <img  src="images/set/Icon2.png" style={{width: "100%"}}></img> */}
      {/* </Grid> */}
      {/* <Grid item md={6} sm={6}>

          </Grid> */}
      {/* <Grid item md={6} sm={6}>
            <img
              className={classes.pic}
              src={"images/set/Icon-43.png"}
              style={{ width: 280 }}
            ></img>
          </Grid> */}
      {/* </Grid> */}
      {/* </div> */}
      <div className={classes.container1}></div>
      <div>
        <Grid spacing={5}>
          <Grid className={classes.ss} item sm={12}>
            <Container className={classes.containercustomer}>
              <h2>SMART SET</h2>
            </Container>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <div className={classes.smartsetcontainer}>
              <Slider {...settingsmartset}>
                {contentSmartset.map((item, index) => (
                  <div key={index.toString()} className={classes.item}>
                    <img
                      src={item.image}
                      style={{ width: "92%" }}
                      // alt={item.title}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.customerdiv}>
        <Grid spacing={5}>
          <Grid className={classes.ss} item sm={12}>
            <Container className={classes.containercustomer}>
              <h2 className={classes.topich2}>CUSTOMER</h2>
            </Container>
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <div className={classes.customercontainer}>
              <Slider {...settings}>
                {content.map((item, index) => (
                  <div key={index.toString()} className={classes.item}>
                    <img
                      src={item.image}
                      style={{ width: "92%" }}
                      // alt={item.title}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
