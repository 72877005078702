import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../component/Header";
import Typography from "@material-ui/core/Typography";
import Footer from "../component/Footer";
import { Container } from "@material-ui/core";


const useStyle = makeStyles((theme) => ({
  root: {
    //   backgroundImage: "url(" + backgroundpic + ")",
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paperStyle: {
    padding: 20,
    height: "70vh",
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      width: "100%",
      height: "100%",
    },
    backgroundColor: "rgba(230, 230, 250, 0.3)",
    backdropFilter: "blur(8px)",
  },
  logo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  div: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(6),
  },
  submit: {
    background: "linear-gradient(45deg, #8C52FF 30%, #5653ff 90%)",
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(195, 155, 245, .3)",
    border: 0,
    color: "white",
    // backgroundColor: "#3f51b5",
    margin: theme.spacing(6, 0, 2),
  },
}));

function Main() {
  const classes = useStyle();

  return (
    <div>
      <Header />
      <Footer />
    </div>
  );
}

export default Main;
