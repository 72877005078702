import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Main from "./pages/Main";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Application from "./pages/Application";
import Store from "./pages/Store";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/application" element={<Application />} />
          <Route exact path="/store" element={<Store />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
