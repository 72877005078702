import React from "react";
import { Typography, Container, IconButton, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: "8vmin",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(2),
  },
  appBar: {
    backgroundColor: "#fbb116",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
  },
  iconbutton: {
    marginTop: theme.spacing(9),
  },
  containerwhite: {
    background: "#fff",
    width: "100%",
  },
  container: {
    padding: "40px",
    background: "#323232",
    color: "#fff",
  },
  containerstore: {
    margin: theme.spacing(8),
  },
  leftpic: {
    // alignItems: "left",
    // float: "left"
    // position: "absolute"
  },
  typo: {
    textAlign: "left",
    textIndent: "50px",
    margin: theme.spacing(2),
    fontSize: "16px"
  },
  img: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  button: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  display: {
    marginTop: theme.spacing(4)
  }
}));

function Application() {
  const classes = useStyles();

  return (
    <div>
      <div container className={classes.containerstore}>
        <IconButton
          color="primary"
          className={classes.button}
          href={"https://apps.apple.com/th/app/modela-iot/id1382251131?l=th"}
          target="_blank"
        >
          <img style={{ width: 200 }} src="/images/set/appstore.png" />
        </IconButton>
        <IconButton
          color="primary"
          className={classes.button}
          href={
            "https://play.google.com/store/apps/details?id=cc.blynk.appexport.modelaiot&hl=th&gl=US&pli=1"
          }
          target="_blank"
        >
          <img style={{ width: 200 }} src="/images/set/googleplay.png" />
        </IconButton>
      </div>

      <div className={classes.containerwhite}>
        <Grid  container alignItems="center" direction="row">
          <Grid item xs={6} sm={3}>
            <img
              className={classes.leftpic}
              style={{ height: 100 }}
              src="/images/set/Icon-41.png"
            />
            <img
              className={classes.leftpic}
              style={{ height: 800 }}
              src="/images/set/Icon-40.png"
            />
          </Grid>
          <Grid item sm={3}></Grid>
          <Grid item xs={6} sm={5}>
            <Typography className={classes.typo}>
              Modela Smart Control "IoT Smart Farm" kit measures and sets up
              automatic control for the 5 main environmental factors that affect
              plant growth, including temperature, air humidity, soil moisture,
              light, and carbon dioxide gas. Additionally, there are 4
              supplementary factors, including VPD, pH, EC, and Flow.
            </Typography>
            <Typography className={classes.typo}>
              In the current state of changing weather conditions due to global
              warming, global crop yields are decreasing while the demand for
              food is increasing. In addition, respiratory disease outbreaks
              make it difficult to travel for agricultural purposes.
            </Typography>
            <Typography className={classes.typo}>
              This is the appropriate time to find ways to increase productivity
              with an automated management system like Modela Smart Control "IoT
              Smart Farm" to meet the growing demand professionally.
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
      </div>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Grid item sm={1}></Grid>
          <Grid item xs={3} sm={5}>
            <Typography className={classes.typo}>
              The Modela IoT Free App allows users to control electrical
              equipment using mobile devices such as Smart Farm, Smart Switch &
              Plug, Smart Home, Lighting, Watering, Power Monitoring, and
              Weather Monitoring. It is the first IoT app designed on the
              concept of "Universal Input" that supports multiple sensor inputs,
              with a user interface available in both Thai and English
              languages. The app includes smart features such as control by
              sensor set point, control on-off by timer, and scheduling.
              Products can easily connect to WiFi networks through an AP mode
              workflow, and notifications can be received on smartphones.
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item xs={6} sm={5}>
            <Typography style={{ color: "#fbb116", fontSize: "40px" }}>
              "Given You Everything"
            </Typography>
            <Typography style={{ fontSize: "40px" }}>
              Model Smart Control
            </Typography>
            <img className={classes.display} style={{ height: 800 }} src="/images/set/Icon-79.png" />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          direction="row"
          // justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} sm={6}>
            <img style={{ height: 800 }} src="/images/set/Icon-77.png" />
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={4}>
            <Typography className={classes.typo}>
              Modela IoT is an Internet of Things company that designs and
              assembles products in Thailand for controlling electrical devices
              using sensors. The product can be used in various applications,
              including agriculture, home use, industrial factories, weather
              stations, and more. The Modela IoT app is available on both iOS
              and Android and can connect to the internet through WiFi 2.4G,
              hotspot WiFi, or pocket WiFi with a mobile SIM card. The device
              features a 2-channel relay for separate equipment zones and
              supports at least 35 different sensors. It can connect to a wide
              range of electrical loads, such as alarms, sirens, water pumps,
              solenoid valves, fans, and light bulbs.
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
      </div>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Grid item sm={1}></Grid>
          <Grid item xs={3} sm={5}>
            <Typography className={classes.typo}>
              Users can set the time to turn equipment on and off, select the
              day of the week, and set a working time and timer delay for
              turnoff. The app allows users to view statistics in real-time and
              for the past year, sync the switch in the Modela IoT Box with the
              mobile app, and set automatic control settings such as high and
              low sensor limits. The app also includes timer, time sequence, and
              time period action settings, history graphs, and schedule export
              to Excel via email. Users can receive notifications via mobile
              push notifications. The Modela IoT Box is made with ABS plastic
              with DIN rail and can be installed in a cabinet rail. It supports
              both 220VAC electric systems and solar 12VDC systems. The device
              comes with a video tutorial, instruction manual, and electrical
              connection manual, and a 1-year warranty is included.
            </Typography>
          </Grid>
          <Grid item sm={2}></Grid>
          <Grid item xs={6} sm={4}>
            <Typography style={{ color: "#fbb116", fontSize: "40px" }}>
              Display Graphs
            </Typography>
            <Typography style={{ fontSize: "40px" }}>
              According to Sensors
            </Typography>
            <img className={classes.display} style={{ height: 700 }} src="/images/set/Icon-43.png" />
          </Grid>
        </Grid>
      </div>
      <div className={classes.containerwhite}>
        <Grid container alignItems="center" direction="row">
          <Grid item sm={1}></Grid>
          <Grid item xs={6} sm={3}>
            <img className={classes.img} style={{ height: 800 }} src="/images/set/Icon-45.png" />
          </Grid>
          <Grid item sm={2}></Grid>
          <Grid item sm={5}>
            <Typography className={classes.typo}>
              Modela Smart Farm Control Box can be controlled via mobile devices
              and can set the time for watering. Equipped with a solar solenoid
              valve and pump control set.
            </Typography>
            <Typography className={classes.typo}>
              The settings can be configured to open and close in zones 1-5 or
              zones 2-5.
            </Typography>
            <Typography className={classes.typo}>
              The watering time can be set for any duration. Once the time is
              up, the system will automatically close the water zone and open
              the next zone, sequentially until all zones are completed, without
              the need for manual intervention. This saves time and energy, and
              produces better crop yields. A delay time can be set after each
              watering zone, for example, watering for 10 minutes, then a
              5-minute delay, and then watering the next zone automatically.
            </Typography>
            <Typography className={classes.typo}>
              The start time for watering can be set to any of the 4 available
              time slots per day.
            </Typography>
            <Typography className={classes.typo}>
              The watering days can be selected for the week, for example, only
              Monday to Friday.
            </Typography>
            <Typography className={classes.typo}>
              The product includes everything from control boards, solenoid
              valves, to sensor control factors. It allows you to control your
              farm or garden from home using your mobile device.
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
      </div>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={1}></Grid>
          <Grid item xs={3} sm={5}>
            <Typography className={classes.typo}>
              Increase productivity and boost profits with the WiFi water pump
              controller with amazing options! You can turn the water pump on
              and off from anywhere in the world through your mobile phone. It
              solves the following problems:
            </Typography>
            <Typography className={classes.typo}>
              You can turn on and off the water pump from anywhere in the world,
              even if you're not at the work site. If you forget to turn on the
              water pump, you can easily set a timer through the mobile app. You
              can control other devices as well because there are two separate
              control channels for electrical devices.
            </Typography>
          </Grid>
          <Grid item sm={2}></Grid>
          <Grid item xs={6} sm={3}>
            <img style={{ height: 800 }} src="/images/set/Icon-46.png" />
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Application;
